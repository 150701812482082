<template>
  <div class="page">
    <van-skeleton title
                  :row="3"
                  :loading="!cart">
      <van-empty v-if="isready && (!cart || !cart.total)"
                 description="购物车是空的，去添加商品吧">
        <van-button round
                    type="danger"
                    class="bottom-button w180px"
                    :to="{name: 'index'}">
          去 选 购
        </van-button>
      </van-empty>
      <van-cell-group title="购物车商品"
                      v-if="isready && cart && cart.total">
        <van-checkbox-group v-model="selection">
          <template v-for="item in cart.list">
            <div :key="item.recid">
              <van-card :num="item.num"
                        :price="item.price_format"
                        currency=""
                        :title="item.title"
                        :thumb="item.titlepic"
                        :desc="item.attr"
                        @click-thumb="showInfo(item)">
                <van-stepper slot="num"
                             v-model="item.num"
                             @change="doEditItem(item)" />
              </van-card>
              <transition name="van-fade">
                <van-cell v-if="editable">
                  <span class="fl">
                    <van-checkbox ref="checks"
                                  :name="item.recid">选择</van-checkbox>
                  </span>
                  <span class="fr">
                    <van-button type="info"
                                size="small"
                                icon="star-o">移到收藏夹</van-button>
                    <van-button type="danger"
                                size="small"
                                icon="delete"
                                @click="doRemove([item.recid])">删除</van-button>
                  </span>
                </van-cell>
              </transition>
            </div>
          </template>
        </van-checkbox-group>
      </van-cell-group>
      <transition name="van-fade">
        <van-cell-group v-if="editable">
          <van-cell>
            <van-button size="small"
                        icon="delete"
                        @click="doRemove(selection)">删除选中</van-button>
            <van-button type="danger"
                        size="small"
                        icon="delete"
                        @click="doClear">清空购物车</van-button>
          </van-cell>
        </van-cell-group>
      </transition>
      <van-cell-group class="mt-20">
        <van-cell>
          <div class="tc fs120 danger">为你推荐</div>
        </van-cell>
      </van-cell-group>
      <recommend :info="recommends"></recommend>
      <van-submit-bar v-if="cart"
                      :price="cart.totalmoney*100"
                      :button-text="`去结算(${cart.total}件)`"
                      @submit="$router.push({name: 'checkout'})">
        <van-checkbox v-model="editable"
                      checked-color="#07c160">修改</van-checkbox>
      </van-submit-bar>
    </van-skeleton>
  </div>
</template>

<script>
import { forEach } from '@/libs/tools';
import Vue from 'vue';
import recommend from '../components/recommend.vue'
import { Card, Tag, Stepper, SubmitBar, Button, CellGroup, Cell, Empty, Checkbox, CheckboxGroup, Dialog } from 'vant';
Vue.use(Card).use(Tag).use(Stepper).use(SubmitBar).use(Button).use(CellGroup).use(Cell).use(Empty).use(Checkbox).use(CheckboxGroup);

export default {
  name: 'Cart',
  components: { recommend },
  data () {
    return {
      cart: null,
      isready: false,
      editable: false,
      tabIndex: 0,
      recommends: {},
      allSelect: false,
      selection: [],
      timer: null,
      timerStep: 0,
      nums: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      size: 20
    }
  },
  methods: {
    init () {
      this.$http.get('e/ShopSys/buycar/', { size: this.size }).then(res => {
        if (res.code === 0) {
          let { recommends, cart } = res.data;
          this.recommends = recommends;
          this.cart = res.data.cart;
          this.$store.commit('setCart', res.data.cart);
          this.isready = true;
        }
      })
    },
    doRemove (recid) {
      Dialog.confirm({ message: '删除商品？' }).then(() => {
        this.$http.post('e/ShopSys/?do=DelBuycar', { recid })
          .then(res => {
            if (res.code === 0) {
              this.init();
            }
          });
      })
    },
    doClear () {
      Dialog.confirm({ message: '清空所有商品？' }).then(() => {
        this.$http.post('e/ShopSys/?do=ClearBuycar')
          .then(res => {
            if (res.code === 0) this.init();
          })
      })
    },
    doDropToFava (recid) {
      Dialog.confirm({ message: '将商品转移到收藏？' }).then(() => {
        this.$http.post('e/ShopSys/?do=MoveInfoToFava', { recid })
          .then(res => {
            if (res.code === 0) this.init();
          })
      })
    },
    doEditItem (item) {
      this.$http.post('e/ShopSys/?do=EditBuycar', item)
        .then(res => {
          if (res.code === 0) {
            this.cart = res.data;
            this.$store.commit('setCart', res.data);
          }
        })
    }
  },
  activated () {
    // 关闭底部Tabbar
    this.$store.commit('setShowTabbar', false)
    this.init()
    this.$store.commit('setPageTitle', '购物车信息')
  },
  beforeDestroy () {
    // 显示底部Tabbar
    this.$store.commit('setShowTabbar', true)
  },
  deactivated () {
    // 显示底部Tabbar
    window.scrollTo(0, 0)
    this.$store.commit('setShowTabbar', true)
  }

}
</script>

<style scoped>
.linked-header {
  font-size: 16px;
  text-align: center;
  padding: 8px;
  font-weight: 500;
  letter-spacing: 2px;
  color: red;
  background-color: #f0f0f0;
}
.empty-content {
  font-size: 14px;
  line-height: 30px;
  text-align: center;
}
</style>
