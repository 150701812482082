<template>
  <van-tabs v-if="info" animated>
    <van-tab v-for="(reco, index) in info"
             :key="index"
             :title="reco.title">
      <goods-grid :list="reco.list"
                  :is-video="isVideo"></goods-grid>
      <div class="pv-5">
        <van-button block @click="$router.push({name: 'goodsList', params:{sort: reco.moreurl}})">查看更多</van-button>
      </div>
    </van-tab>
  </van-tabs>
</template>

<script>
import goodsGrid from './goodsGrid.vue'
import Vue from 'vue';
import { Button, Tab, Tabs } from 'vant';
Vue.use(Button).use(Tab).use(Tabs);


export default {
  name: 'recommend',
  components: { goodsGrid },
  props: {
    info: {
      type: [Object, Array]
    },
    isVideo: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
</style>